<script>
export default {
  name: 'AppTitle',
  computed: {
    title() {
      return this.$route.meta?.title || ''
    },
  },
}
</script>

<template>
  <div
    v-show="title"
    class="text-h7 font-weight-black title-line"
  >
    {{ title }}
  </div>
</template>

<style lang='scss' scoped>
.title-line {
  position: relative;

  &::before {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: -12px;
    width: 6px;
    height: 100%;
    background-color: var(--v-primary-base);
  }
}
</style>
