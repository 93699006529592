<script>
import { useRouter } from '@core/utils'
import {
  mdiAccountOutline,
  mdiEmailOutline,
  mdiCheckboxMarkedOutline,
  mdiChatOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
} from '@mdi/js'
import { toastSuccess } from '@core/utils/prompt'

export default {
  name: 'AppAvatar',
  setup() {
    const { router } = useRouter()
    const userData = JSON.parse(localStorage.getItem('ssoUser'))

    function logout() {
      localStorage.removeItem('ssoToken')
      localStorage.removeItem('ssoUser')
      localStorage.removeItem('ssoAPP')

      toastSuccess('退出成功')
      setTimeout(() => router.push({ name: 'sso-login' }), 2000)
    }

    return {
      userData,
      logout,
      icons: {
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCheckboxMarkedOutline,
        mdiChatOutline,
        mdiCogOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
      },
    }
  },
}
</script>

<template>
  <v-menu
    offset-y
    left
    nudge-bottom="14"
    nudge-right="30"
    min-width="230"
    content-class="user-profile-menu-content"
  >
    <template #activator="{ on, attrs }">
      <v-badge
        bottom
        color="success"
        overlap
        offset-x="12"
        offset-y="12"
        class="ms-4"
        dot
      >
        <v-avatar
          size="40px"
          v-bind="attrs"
          color="primary"
          class="v-avatar-light-bg primary--text"
          v-on="on"
        >
          <v-img
            v-if="userData.avatar"
            :src="userData.avatar"
          ></v-img>
          <v-icon
            v-else
            color="primary"
            size="28"
          >
            {{ icons.mdiAccountOutline }}
          </v-icon>
        </v-avatar>
      </v-badge>
    </template>
    <v-list>
      <!-- Logout -->
      <v-list-item @click="logout">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiLogoutVariant }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>退出</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<style scoped lang='scss'>
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
.overflow{
  width: 150px;
  /*1.先强制一行内显示文本*/
  white-space: nowrap;
  /*2.超出的部分隐藏*/
  overflow: hidden;
  /*3.文字用省略号替代超出的部分*/
  text-overflow: ellipsis;
}
</style>
