<script>
import { computed } from '@vue/composition-api'
import AppAvatar from '@/views/sso/AppAvatar.vue'
import AppTitle from './components/AppTitle.vue'
import Menu from './components/Menu.vue'

export default {
  name: 'EntryIndex',
  components: { AppTitle, AppAvatar, Menu },
  setup() {
    const ssoUser = localStorage.getItem('ssoUser') ? JSON.parse(localStorage.ssoUser) : {}
    const username = computed(() => ssoUser?.nickname || ssoUser?.username || '') // 昵称

    return {
      username,
    }
  },
}
</script>

<template>
  <div class="app-view">
    <div style="width: 256px; flex-shrink: 0">
      <v-navigation-drawer dark permanent style="background: #28243d; width: 256px">
        <Menu />
      </v-navigation-drawer>
    </div>
    <v-main class="app-main">
      <!-- 菜单部分 -->
      <v-app-bar class="header-top px30" elevation="1" height="60">
        <app-title />
        <v-spacer />
        <div>
          欢迎您，
          <span class="username">{{ username }}</span>
        </div>
        <app-avatar />
      </v-app-bar>
      <v-main class="app-main-body">
        <transition appear mode="out-in" name="scroll-x-transition">
          <router-view :key="$route.fullPath" />
        </transition>
      </v-main>
    </v-main>
  </div>
</template>

<style lang='scss' scoped>
.app-view {
  display: flex;
  width: 100vw;
  height: 100vh;
}
.header-top {
  ::v-deep .v-toolbar__content {
    padding: 0 !important;
  }
}
.app-header {
  height: 60px;
  flex: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 .5rem;
  &-right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 20px;
    font-weight: bold;
    padding-left: 50px;

    img {
      height: 30px;
    }
  }
}

.app-main {
  padding: 0!important;
  overflow: hidden;
  display: flex;
  flex: auto;

  ::v-deep .v-main__wrap {
    display: flex;
    flex-direction: column;
  }
  &-body {
    height: calc(100% - 32px);
    margin: 16px !important;
    width: calc(100% - 32px);
    flex: auto;
    overflow: hidden;
    overflow-y: auto;

    ::v-deep .v-main__wrap {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
