import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-menu"},[_vm._m(0),_c(VList,{staticClass:"pl0",attrs:{"nav":"","shaped":""}},[_c(VListItemGroup,{attrs:{"color":"primary","mandatory":""},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_vm._l((_vm.menu),function(item){return [_c(VListItem,{key:item.path,staticStyle:{"height":"44px"},attrs:{"value":item.path},on:{"click":function($event){return _vm.jumpTo(item.path)}}},[_c(VListItemIcon,[_c(VIcon,{attrs:{"size":"22"}})],1),_c(VListItemTitle,{staticStyle:{"font-size":"16px"}},[_vm._v(" "+_vm._s(item.title)+" ")])],1)]})],2)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-menu-title"},[_c('img',{attrs:{"alt":"logo","src":require("@/assets/images/login/logo.png")}}),_c('span',{staticClass:"app-menu-title-text pl6"},[_vm._v(" 小五云平台 ")])])}]

export { render, staticRenderFns }