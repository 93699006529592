<script>
import { ref, watch } from '@vue/composition-api'
import { useRouter } from '@core/utils'

export default {
  name: 'Menu',
  setup() {
    const active = ref('/home')
    const { router, route } = useRouter()
    active.value = route.value.meta?.activeMenu || route.value.path
    const menu = ref([
      {
        path: '/MyApps',
        title: '我的应用',
      },
      {
        path: '/WorkFlow',
        title: '我的审批',
      },
    ])

    function jumpTo(path) {
      if (route.value.path === path) return false
      router.push(path)
    }

    return {
      active,
      menu,
      jumpTo,
    }
  },
}
</script>

<template>
  <div class="app-menu">
    <div class="app-menu-title">
      <img alt="logo" src="@/assets/images/login/logo.png">
      <span class="app-menu-title-text pl6">
        小五云平台
      </span>
    </div>
    <v-list class="pl0" nav shaped>
      <v-list-item-group v-model="active" color="primary" mandatory>
        <template v-for="item in menu">
          <v-list-item :key="item.path" :value="item.path" style="height: 44px" @click="jumpTo(item.path)">
            <v-list-item-icon>
              <v-icon size="22" />
            </v-list-item-icon>
            <v-list-item-title style="font-size: 16px">
              {{ item.title }}
            </v-list-item-title>
          </v-list-item>
        </template>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<style lang='scss' scoped>
.app-menu {
  &-title {
    display: flex;
    align-items: center;
    justify-content: left;
    height: 60px;
    padding-left: 44px;

    &-text {
      font-size: 18px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      color: #FFFFFF;
    }

    img {
      height: 30px;
    }
  }
}

.v-item--active {
  background: linear-gradient(98deg, #c48eff, var(--v-primary-base) 94%);
  color: #ffffff !important;
}
</style>
